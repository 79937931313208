import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { widState } from "../../../Store/workdriveState";
import MySortableTree from "../../Others/SortableTree/MySortableTree";
import RootFolderSelection from "../../SettingsPage/RootFolderSelection/RootFolderSelection";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import axios from "axios";
import ClipboardCopy from "../../RelatedList/srcfolder/components/WorkDrive/Modals/ClipboardCopy";
import SnackAlert from "../../RelatedList/srcfolder/components/WorkDrive/Others/SnackAlert";
const ZOHO = window.ZOHO;

const customHeading = {
  fontWeight: 500,
  fontSize: "18px",
  color: "rgba(0, 0, 0, 0.87)",
};
const customDescription = {
  fontSize: "16px",
  color: "rgba(0, 0, 0, 0.6)",
};

export default function CreateActionNew({
  moduleName,
  fieldOptions,
  setPage,
  existingData,
}) {
  //
  const snap = useSnapshot(widState);
  const [finalSaveLoading, setFinalSaveLoading] = useState(false);
  const [settingDetails, setSettingDetails] = useState({});
  const [fieldApiName, setFieldApiName] = useState(null);
  const [rootFolderFieldName, setRootFolderFieldName] = useState(null);
  const [externalSharedUrlSave, setExternalSharedUrlSave] = useState(null);
  const [externalSharedUrlFieldName, setExternalSharedUrlFieldName] =
    useState(null);
  // const [SpecificFolderName, setSpecificFolderName] = useState();
  const [step, setStep] = useState(1);
  const [treeData, setTreeData] = useState([
    {
      title: "Folder Name",
      id: 0,
      expanded: true,
    },
  ]);
  const [saveFolderStructure, setSaveFolderStructure] = useState(false);
  const [buttonState, setButtonState] = useState({
    saveFolderId: null,
    isSpecificFolder: null,
  });

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const prevSettings = existingData?.setting_schema
      ? JSON.parse(existingData?.setting_schema)
      : {};
    if (
      prevSettings?.rootFolder ||
      prevSettings?.teamFolder ||
      prevSettings?.workspace
    ) {
      // if (prevSettings?.rootFolder) {
      //   setSpecificFolderName(prevSettings?.rootFolder?.name);
      // }
      const data = {
        rootFolder: prevSettings?.rootFolder,
        teamFolder: prevSettings?.teamFolder,
        workspace: prevSettings?.workspace,
      };
      setSettingDetails(data);
    }
    if (prevSettings?.fieldApiName) {
      setFieldApiName(prevSettings?.fieldApiName);
    }
    if (prevSettings?.rootFolderFieldName) {
      setRootFolderFieldName(prevSettings?.rootFolderFieldName);
    }
    if (prevSettings?.treeData) {
      setTreeData(prevSettings?.treeData);
      setStep(5);
    }
    if (prevSettings?.buttonState) {
      setButtonState(prevSettings?.buttonState);
    }
    if (prevSettings?.externalSharedUrlSave) {
      setExternalSharedUrlSave(prevSettings?.externalSharedUrlSave);
      setStep(6);
    }
    if (prevSettings?.externalSharedUrlFieldName) {
      setExternalSharedUrlFieldName(prevSettings?.externalSharedUrlFieldName);
      setStep(7);
    }
    // if (existingData?.setting_id) setStep(5);
  }, [existingData]);

  // ;

  const handleSave = async (event) => {
    event.preventDefault();
    setFinalSaveLoading(true);
    let data = {
      moduleApiName: moduleName,
      rootFolder: settingDetails?.rootFolder,
      teamFolder: settingDetails?.teamFolder,
      workspace: settingDetails?.workspace,
      fieldApiName: fieldApiName,
      rootFolderFieldName: rootFolderFieldName,
      externalSharedUrlSave: externalSharedUrlSave,
      externalSharedUrlFieldName: externalSharedUrlFieldName,
      treeData: treeData,
      settingType: "relatedList",
      buttonState: buttonState,
    };

    const insertedData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingId: existingData?.setting_id || "",
        settingSchema: data,
        showInRelatedList: false,
        moduleApiName: moduleName,
        settingType: "workflow",
      },
      headers: {
        orgid: widState.orgId,
        apikey: widState.apiKey,
        datacenterurl: widState.dataCenterUrl,
        connname: "easygoogledriveforcrm",
      },
    });

    const settingId = insertedData?.data?.data?.setting_id || "";

    if (settingId !== "") {
      await ZOHO.CRM.ACTION.setConfig({
        settingId: settingId,
        orgid: snap.orgId,
        datacenterurl: widState.dataCenterUrl,
        actionType: "Create Action",
        // apikey: snap.apiKey,
        connname: "easygoogledriveforcrm",
      });
    }
    setFinalSaveLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          bgcolor: "#FAF9F6",
        }}
      >
        <Box sx={{ width: "68%", position: "relative", height: "100vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: existingData?.setting_id && "space-between",
              gap: 2,
              alignItems: "center",
              mx: 3,
              my: 1,
            }}
          >
            {setPage && (
              <ArrowBackIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setPage(null)}
              />
            )}

            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
              Create Folder
            </Typography>

            {existingData?.setting_id ? (
              <Box
                sx={{
                  mt: 1.1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ fontWeight: "medium" }}>
                  Setting Id:
                </Typography>
                <Typography sx={{ ml: 0.3, fontSize: 14, mt: 0.2 }}>
                  {existingData?.setting_id}
                </Typography>
                <ClipboardCopy
                  color={"transparent"}
                  copyText={existingData?.setting_id}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box sx={{ mx: 2, padding: 1 }}>
            {step === 1 && (
              <Box sx={{ mb: 1 }}>
                <Typography
                  sx={{ mb: 1, mt: 2, fontSize: 20, fontWeight: 600 }}
                >
                  Root Folder Type
                </Typography>
                <Box
                  onClick={() => {
                    setFieldApiName(null);
                    setSaveFolderStructure(false);
                    setTreeData([
                      {
                        title: "Folder Name",
                        id: 0,
                        expanded: true,
                      },
                    ]);
                    setButtonState({
                      ...buttonState,
                      isSpecificFolder: true,
                    });
                    setRootFolderFieldName(null);
                    setStep(4);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    // height: 40,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>Static Root Folder</Typography>
                  <br />
                  <Typography sx={customDescription}>
                    All <strong>{moduleName}</strong> will be saved in one
                    Specific Root Folder. <br /> You can able to create New
                    Folder of {moduleName} if not crated earlier. Generally it
                    is used On Create Workflow to Create Folder for New{" "}
                    {moduleName}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setSettingDetails({});
                    setSaveFolderStructure(false);
                    setTreeData([
                      {
                        title: "Folder Name",
                        id: 0,
                        expanded: true,
                      },
                    ]);
                    setButtonState({
                      saveFolderId: null,
                      isSpecificFolder: false,
                    });
                    setExternalSharedUrlSave(null);
                    setExternalSharedUrlFieldName(null);
                    setFieldApiName(null);
                    setStep(4);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>
                    Dynamic Root Folder
                  </Typography>
                  <br />
                  <Typography sx={customDescription}>
                    Root folder is saved to {moduleName} record. <br /> It is
                    useful when Record's foder is already created and saved and
                    you want to create Sub-Folder under certain condition.
                  </Typography>
                </Box>
              </Box>
            )}

            {step === 2 && (
              <Box>
                <Typography sx={customHeading}>
                  Save Folder ID to {moduleName} Record?
                </Typography>

                <Box
                  onClick={() => {
                    setButtonState({
                      ...buttonState,
                      saveFolderId: true,
                    });
                    setExternalSharedUrlSave(null);
                    setStep(3);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    // height: 40,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>
                    Yes - I want to save folder ID.
                  </Typography>
                  <Typography sx={customDescription}>
                    Save Folder ID to {moduleName} Record. The Folder ID is
                    necessary to visualize, create, edit or delete the Workdrive
                    Folder as Related List widget and can be used in Deluge
                    Codes later. It can be useful to create Sub-Folder Later.
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setButtonState({
                      ...buttonState,
                      saveFolderId: false,
                    });
                    if (externalSharedUrlSave !== "No") {
                      setExternalSharedUrlSave(null);
                    }
                    setFieldApiName(null);

                    setStep(6);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    // height: 40,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>
                    No - Folder ID is not used later
                  </Typography>
                  <Typography sx={customDescription}>
                    I just want to Create Folder and Sub Folders, do not want to
                    use it later in Deluge or Widgets.
                  </Typography>
                </Box>
              </Box>
            )}

            {step === 3 && buttonState.saveFolderId && (
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ my: 1, fontSize: 20, fontWeight: 600 }}>
                  Provide Field Name
                </Typography>
                <Typography sx={{ my: 1, fontSize: 16 }}>
                  We decided to save Folder ID to {moduleName} Record. <br />
                  <br />A list of Single Line Fields of {moduleName} Module are
                  shown below. You need to select a Field where created Folder
                  ID will be saved for further use. <br />
                  <br />
                  If desired Field Name is not created yet, Please crate the
                  Single Line Field and do the same again.
                </Typography>
                <br />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={
                    fieldApiName?.display_name
                      ? {
                          field_label: fieldApiName?.display_name,
                        }
                      : null
                  }
                  options={fieldOptions?.filter(
                    (item) => item.data_type === "text"
                  )}
                  getOptionLabel={(option) => option.field_label}
                  onChange={(e, v) => {
                    if (v) {
                      setFieldApiName({
                        display_name: v.field_label,
                        api_name: v.api_name,
                      });
                      setStep(6);
                    } else {
                      setFieldApiName(null);
                    }
                  }}
                  size="small"
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Field Name" />
                  )}
                />
              </Box>
            )}

            {step === 4 && buttonState.isSpecificFolder && (
              <>
                <Typography sx={customHeading}>
                  Root Folder Selection
                </Typography>
                <br />
                <Typography sx={customDescription}>
                  You need to select Root Folder for {moduleName} Module. All
                  folders created for {moduleName} Module will be saved in this
                  folder <br />
                  <br />
                  Navigate to the appropriate Team Folder and create new folder
                  for {moduleName} Module or select an existing one.
                  <br /> <br /> Click the "Select Root Folder" button to
                  Create/Select Root Folder
                </Typography>
                <br />
                <br />
                <RootFolderSelection
                  setStep={setStep}
                  // setSpecificFolderName={setSpecificFolderName}
                  settingData={settingDetails}
                  saveApiData={(data) => {
                    widState?.setApiData(data);
                  }}
                  module={moduleName}
                  saveData={(data) => {
                    const { key, response } = data;
                    setSettingDetails((prev) => {
                      // console.log({
                      //   settingDetails: {
                      //     ...prev,
                      //     [key]: response,
                      //   },
                      // });
                      return {
                        ...prev,
                        [key]: response,
                      };
                    });
                  }}
                  apiData={widState.apiData}
                  folderDetails={widState?.folderDetails}
                  setFolderDetails={(data) => {
                    widState?.setFolderDetails(data);
                  }}
                  addFolder={(data) => {
                    widState.addFolder(data);
                  }}
                  connname={snap.connName}
                  orgid={snap.orgId}
                  apikey={snap.apiKey}
                  datacenterurl={snap.dataCenterUrl}
                  settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
                  zuid={snap.zuid}
                />
              </>
            )}

            {step === 4 && buttonState.isSpecificFolder === false && (
              <Box sx={{ mb: 1 }}>
                <Typography sx={customHeading}>Select Field Name</Typography>
                <br />
                <Typography sx={customDescription}>
                  Select Field Name where Root Folder is Saved. For Creating New
                  Folders, Values in this Field will be used as Root Folder ID
                </Typography>
                <br />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={fieldOptions?.filter(
                    (item) => item.data_type === "text"
                  )}
                  value={
                    rootFolderFieldName?.display_name
                      ? {
                          field_label: rootFolderFieldName?.display_name,
                        }
                      : null
                  }
                  getOptionLabel={(option) => option.field_label}
                  onChange={(e, v) => {
                    if (v) {
                      setRootFolderFieldName({
                        display_name: v.field_label,
                        api_name: v.api_name,
                      });
                      setStep(5);
                    } else {
                      setRootFolderFieldName(null);
                    }
                  }}
                  size="small"
                  sx={{ width: 250 }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            )}

            {step === 5 && (
              <Box sx={{ my: 1 }}>
                <Typography sx={customHeading}>
                  Root Folder Name and Nested Folder Structure
                </Typography>
                <br />
                <Typography sx={customDescription}>
                  Provide a Folder Name for {moduleName} Record. You can click +
                  to add Nested Folder Structure. You can rearrange the Folders
                  Structure by Draging.
                  <br />
                  <br /> For each Folder and Subfolder, you need to mention how
                  names will be used, use # to use Records Data to make it
                  dynamic. Once finished, click "Save Folder Structure".
                </Typography>
                <br />

                <MySortableTree
                  // BUG Reset it once buttonState.isSpeciic folder is changed
                  moduleName={moduleName}
                  myTreeData={treeData}
                  savedData={(data) => setTreeData(data?.response ?? [])}
                  allowMultipleRoots={
                    buttonState.isSpecificFolder === null
                      ? false
                      : !buttonState?.isSpecificFolder
                  }
                />
              </Box>
            )}

            {step === 5 && (
              <Box sx={{ position: "absolute", bottom: 12, left: 225 }}>
                <Button
                  onClick={() =>
                    buttonState?.isSpecificFolder
                      ? setStep(2)
                      : setSaveFolderStructure(true)
                  }
                  size="small"
                  variant="contained"
                >
                  Save Folder Structure
                </Button>
              </Box>
            )}

            {step === 6 && (
              <Box>
                <Typography sx={customHeading}>
                  Do you want to generate External Shared URL for this Folder?
                </Typography>

                <Box
                  onClick={() => {
                    setExternalSharedUrlSave("Yes");
                    setStep(7);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    // height: 40,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>
                    Yes - View/Download Only Shared URL
                  </Typography>
                  <Typography sx={customDescription}>
                    Users visiting this URL will only able to View and Download
                    the Content of the Folder.
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setExternalSharedUrlSave("YesWithUpload");
                    setStep(7);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>
                    Yes - Full Access Shared URL
                  </Typography>
                  <Typography sx={customDescription}>
                    Users visiting this URL can Create, View, Download, Upload
                    Files and Folders. They have full access to this Folder.
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setExternalSharedUrlSave("No");
                    setExternalSharedUrlFieldName(null);
                  }}
                  sx={{
                    p: 2,
                    my: 2,
                    alignItems: "center",
                    border: "1px solid #c9cdd4",
                    borderRadius: "7px",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgba(24, 80, 160, 0.04)",
                    },
                  }}
                >
                  <Typography sx={customHeading}>
                    No - Do not create Shared URL
                  </Typography>
                  <Typography sx={customDescription}>
                    I do not want to create Shared URL for this Folder.
                  </Typography>
                </Box>
              </Box>
            )}

            {step === 7 && (
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ my: 1, fontSize: 20, fontWeight: 600 }}>
                  {moduleName} Field Name to save Shared URL
                </Typography>
                <Typography sx={{ my: 1, fontSize: 16 }}>
                  Select {moduleName} Field Name (URL/Text Area Field) to save
                  the Shared URL of the Folder. You can send this Shared Folder
                  URL to others by Email or SMS to Download/Upload Files.
                </Typography>
                <br />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={
                    externalSharedUrlFieldName?.display_name
                      ? {
                          field_label: externalSharedUrlFieldName?.display_name,
                        }
                      : null
                  }
                  options={fieldOptions?.filter(
                    (item) =>
                      item.data_type === "website" ||
                      item.data_type === "textarea"
                  )}
                  getOptionLabel={(option) => option.field_label}
                  onChange={(e, v) => {
                    if (v) {
                      if (fieldApiName?.api_name === v.api_name) {
                        handleClick();
                      } else {
                        setExternalSharedUrlFieldName({
                          display_name: v.field_label,
                          api_name: v.api_name,
                        });
                      }
                    } else {
                      setExternalSharedUrlFieldName(null);
                    }
                  }}
                  size="small"
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Field Name" />
                  )}
                />
              </Box>
            )}
            <SnackAlert
              duration={7000}
              snackOpen={open}
              handleCloseSnack={handleClose}
              severity={"error"}
              message={
                "You can't select same folder name for created folder id and Shared Link. Please select another one"
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "32%",
            px: 2,
            bgcolor: "#FCFBFC",
            boxShadow: "-1px 0px 15px rgba(0, 0, 0, 0.1)",
            // overflow: "auto",
          }}
        >
          {buttonState?.isSpecificFolder !== null && (
            <Box
              onClick={() => setStep(1)}
              sx={{
                my: 1,
                borderRadius: "4px",
                bgcolor: step === 1 ? "#E3F2FD" : "#F5F5F5",
                p: 0.5,
                "&:hover": { cursor: "pointer" },
                color:
                  step === 1 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: step === 1 && "medium",
                  fontSize: 13,
                  p: 1,
                  pb: 0,
                }}
              >
                Root Folder Type
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  p: 0.2,
                  pt: 0,
                }}
              >
                {" "}
                <ArrowRightIcon
                  sx={{
                    fontWeight: step === 1 && "medium",
                    ml: 0,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: step === 1 && "medium",
                    fontSize: 13,
                    mt: 0.3,
                  }}
                >
                  {buttonState?.isSpecificFolder
                    ? "Specific Folder"
                    : "Using Folder ID saved in the Record"}
                </Typography>
              </Box>
            </Box>
          )}

          {buttonState?.isSpecificFolder &&
            settingDetails?.rootFolder?.name && (
              <Box
                onClick={() => setStep(4)}
                sx={{
                  my: 1,
                  borderRadius: "4px",
                  bgcolor: step === 4 ? "#E3F2FD" : "#F5F5F5",
                  p: 0.5,
                  "&:hover": { cursor: "pointer" },
                  color:
                    step === 4 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: step === 4 && "medium",
                    fontSize: 13,
                    p: 1,
                    pb: 0,
                  }}
                >
                  Root Folder Name
                </Typography>
                <Box sx={{ display: "flex", p: 0.2 }}>
                  {" "}
                  <ArrowRightIcon
                    sx={{
                      fontWeight: step === 4 && "medium",
                      ml: 0,
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: step === 4 && "medium",
                      fontSize: 13,
                      mt: 0.3,
                    }}
                  >
                    {settingDetails?.rootFolder?.name}
                  </Typography>
                </Box>
              </Box>
            )}

          {!buttonState?.isSpecificFolder &&
            rootFolderFieldName?.display_name && (
              <Box
                onClick={() => setStep(4)}
                sx={{
                  my: 1,
                  borderRadius: "4px",
                  bgcolor: step === 4 ? "#E3F2FD" : "#F5F5F5",
                  p: 0.5,
                  "&:hover": { cursor: "pointer" },
                  color:
                    step === 4 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: step === 4 && "medium",
                    fontSize: 13,
                    p: 1,
                    pb: 0,
                  }}
                >
                  Select Field Name where Folder Id is saved
                </Typography>
                <Box sx={{ display: "flex", p: 0.2 }}>
                  {" "}
                  <ArrowRightIcon
                    sx={{
                      fontWeight: step === 4 && "medium",
                      ml: 0,
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: step === 4 && "medium",
                      fontSize: 13,
                      mt: 0.3,
                    }}
                  >
                    {rootFolderFieldName?.display_name}
                  </Typography>
                </Box>
              </Box>
            )}

          {buttonState.saveFolderId !== null && (
            <Box
              onClick={() => setStep(2)}
              sx={{
                my: 1,
                borderRadius: "4px",
                bgcolor: step === 2 ? "#E3F2FD" : "#F5F5F5",
                p: 0.5,
                "&:hover": { cursor: "pointer" },
                color:
                  step === 2 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: step === 4 && "medium",
                  fontSize: 13,
                  p: 1,
                  pb: 0,
                }}
              >
                Save Folder ID?
              </Typography>
              {/* <br /> */}

              <Box sx={{ display: "flex", p: 0.2, pt: 0 }}>
                {" "}
                <ArrowRightIcon
                  sx={{
                    fontWeight: step === 2 && "medium",
                    ml: 0,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: step === 2 && "medium",
                    fontSize: 13,
                    mt: 0.3,
                  }}
                >
                  {buttonState.saveFolderId ? "Yes" : "No"}
                </Typography>
              </Box>
            </Box>
          )}
          {fieldApiName?.display_name && (
            <Box
              onClick={() => setStep(3)}
              sx={{
                my: 1,
                borderRadius: "4px",
                bgcolor: step === 3 ? "#E3F2FD" : "#F5F5F5",
                p: 0.5,
                "&:hover": { cursor: "pointer" },
                color:
                  step === 3 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: step === 3 && "medium",
                  fontSize: 13,
                  p: 1,
                  pb: 0,
                }}
              >
                Field Name to Save Folder ID
              </Typography>
              <Box sx={{ display: "flex", p: 0.2 }}>
                {" "}
                <ArrowRightIcon
                  sx={{
                    fontWeight: step === 3 && "medium",
                    ml: 0,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: step === 3 && "medium",
                    fontSize: 13,
                    mt: 0.3,
                  }}
                >
                  {fieldApiName?.display_name}
                </Typography>
              </Box>
            </Box>
          )}
          {externalSharedUrlSave && (
            <Box
              onClick={() => setStep(6)}
              sx={{
                my: 1,
                borderRadius: "4px",
                bgcolor: step === 6 ? "#E3F2FD" : "#F5F5F5",
                p: 0.5,
                "&:hover": { cursor: "pointer" },
                color:
                  step === 6 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: step === 6 && "medium",
                  fontSize: 13,
                  p: 1,
                  pb: 0,
                }}
              >
                Generate and Save External Shared URL?
              </Typography>
              <Box sx={{ display: "flex", p: 0.2 }}>
                {" "}
                <ArrowRightIcon
                  sx={{
                    fontWeight: step === 6 && "medium",
                    ml: 0,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: step === 6 && "medium",
                    fontSize: 13,
                    mt: 0.3,
                  }}
                >
                  {externalSharedUrlSave !== "No"
                    ? externalSharedUrlSave == "YesWithUpload"
                      ? "Yes - Full Access"
                      : "Yes - Download Only"
                    : "No"}
                </Typography>
              </Box>
            </Box>
          )}

          {externalSharedUrlSave && externalSharedUrlSave !== "No" && (
            <Box
              onClick={() => setStep(7)}
              sx={{
                my: 1,
                borderRadius: "4px",
                bgcolor: step === 7 ? "#E3F2FD" : "#F5F5F5",
                p: 0.5,
                mb: 8,
                "&:hover": { cursor: "pointer" },
                color:
                  step === 7 ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: step === 7 && "medium",
                  fontSize: 13,
                  p: 1,
                  pb: 0,
                }}
              >
                Field Name to Save the Shared Link?
              </Typography>
              <Box sx={{ display: "flex", p: 0.2 }}>
                {" "}
                <ArrowRightIcon
                  sx={{
                    fontWeight: step === 7 && "medium",
                    ml: 0,
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: step === 7 && "medium",
                    fontSize: 13,
                    mt: 0.3,
                  }}
                >
                  {externalSharedUrlFieldName?.display_name}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ position: "fixed", bottom: 14, right: 10 }}>
        <Button
          onClick={() => {
            setExternalSharedUrlSave(null);
            setExternalSharedUrlFieldName(null);
            setSettingDetails({});
            setFieldApiName(null);
            setRootFolderFieldName(null);
            setSaveFolderStructure(false);
            // setSpecificFolderName();
            setStep(1);
            setTreeData([
              {
                title: "Folder Name",
                id: 0,
                expanded: true,
              },
            ]);
            setButtonState({
              saveFolderId: null,
              isSpecificFolder: null,
            });
          }}
          // disabled={step === 5 ? false : true}
          size="small"
          sx={{ width: 100, mr: 1.5 }}
          variant="outlined"
        >
          Reset
        </Button>
        <Button
          onClick={handleSave}
          disabled={
            saveFolderStructure ||
            (externalSharedUrlSave &&
              (externalSharedUrlSave === "No" || externalSharedUrlFieldName))
              ? // ||
                // buttonState?.saveFolderId === false
                false
              : true
          }
          size="small"
          sx={{ width: 100 }}
          variant="contained"
        >
          {finalSaveLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </>
  );
}

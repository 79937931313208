import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useEffect, useState } from "react";
import Overview from "../Components/Overview/Overview";
import SettingsPage from "../Components/SettingsPage/SettingsPage";
import axios from "axios";
import { useSnapshot } from "valtio";
import { widState } from "../Store/workdriveState";
import logo from "../Images/easypluginz-label.png";
import FAQ from "../Components/FAQ/FAQ";
import workdrive_to_crm_logo from "../Images/googledrive-for-zoho-crm-logo.png";
import ModulesRelatedFieldSetting from "../Components/ModuleRelatedSetting/ModulesRelatedFieldSetting";

const ZOHO = window.ZOHO;

export default function Settings() {
  const widSnap = useSnapshot(widState);
  const [loading, setLoading] = useState(true);
  const [authLoadingZoho, setAuthLoadingZoho] = useState(false);
  const [authLoadingGoogle, setAuthLoadingGoogle] = useState(false);
  const [revokeLoadingZoho, setRevokeLoadingZoho] = useState(false);
  const [revokeLoadingGoogle, setRevokeLoadingGoogle] = useState(false);
  const [auth, setAuth] = useState("Active");
  const [settings, setSettings] = useState("Inactive");
  const [zohoAuth, setZohoAuth] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);
  const [faq, setFaq] = useState("Inactive");
  const [moduleRelatedSetting, setModuleRelatedSetting] = useState("Inactive");
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zuid, setZuid] = useState(null);

  const [zohoLoaded, setZohoLoaded] = useState(false);

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
        const dataCenterMap = {
          US: "https://www.zohoapis.com",
          EU: "https://www.zohoapis.eu",
          AU: "https://www.zohoapis.com.au",
          IN: "https://www.zohoapis.in",
          China: "https://www.zohoapis.com.cn",
          JP: "https://www.zohoapis.jp",
        };
        setDataCenterUrl(dataCenterMap?.[currentEnv?.deployment] || "");
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  const handleAuthenticateZoho = async () => {
    setAuthLoadingZoho(true);

    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        accountsUrl:
          "https://accounts.zoho." +
          dataCenterUrl?.split("https://www.zohoapis.")?.[1],
        connName: "easygoogledriveforcrm__zoho",
        scope: [
          // "ZohoCRM.users.ALL",
          "ZohoCRM.users.CREATE",
          "ZohoCRM.users.READ",
          "ZohoCRM.users.UPDATE",
          "ZohoCRM.org.READ",
          "ZohoCRM.org.UPDATE",
          "ZohoCRM.settings.CREATE",
          "ZohoCRM.settings.READ",
          "ZohoCRM.settings.UPDATE",
          "ZohoCRM.coql.READ",
          "ZohoCRM.modules.CREATE",
          "ZohoCRM.modules.READ",
          "ZohoCRM.modules.UPDATE",
        ],
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/callback`,
        crmApiDomain: dataCenterUrl,
        zuid: zuid,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheckZoho({
        headers: {
          apikey: apiKey,
          connname: "easygoogledriveforcrm__zoho",
          orgid: orgId,
          accountsurl: dataCenterUrl,
          dataCenterurlvariablename: "easygoogledriveforcrm__Data_Center_URL",
        },
        dataCenterUrl: dataCenterUrl,
      });
      if (authCheckStatus) {
        // Create Setting for WorkDrive
        // BUG we may need to activate it later
        // const defaultFoldersCreated = await axios.request({
        //   url: `${process.env.REACT_APP_API_SERVER_URL}/googledrive/extfolders`,
        //   method: "POST",
        //   data: {},
        //   headers: {
        //     apikey: apiKey,
        //     connname: "easygoogledriveforcrm__google",
        //     orgid: orgId,
        //     datacenterurl: crmApiDomain,
        //   },
        // });
        setZohoAuth(true);
        setAuthLoadingZoho(false);
        if (googleAuth) {
          setSettings("Active");
          setAuth("Successful");
          clearInterval(myAuthCheckInterval);
        }
      } else {
        setSettings("Inactive");
        setAuth("Active");
      }
    }, 5000);

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleAuthenticateGoogle = async () => {
    setAuthLoadingGoogle(true);

    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/google/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        connName: "easygoogledriveforcrm__google",
        scope: [
          // "ZohoCRM.users.ALL",
          // "https://www.googleapis.com/auth/drive",
          // "https://www.googleapis.com/auth/drive.appdata",
          "https://www.googleapis.com/auth/drive.file",
          // "https://www.googleapis.com/auth/drive.metadata",
          // "https://www.googleapis.com/auth/drive.metadata.readonly",
          // "https://www.googleapis.com/auth/drive.photos.readonly",
          // "https://www.googleapis.com/auth/drive.readonly",
          // "https://www.googleapis.com/auth/drive.scripts",
        ],
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/google/callback`,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheckGoogle({
        headers: {
          apikey: apiKey,
          connname: "easygoogledriveforcrm__google",
          orgid: orgId,
          services: "drive",
        },
      });
      console.log(
        "🚀 ~ file: Settings.js:172 ~ myAuthCheckInterval ~ authCheckStatus:",
        authCheckStatus
      );
      if (authCheckStatus) {
        setGoogleAuth(true);
        setAuthLoadingGoogle(false);
        if (zohoAuth) {
          setSettings("Active");
          setAuth("Successful");
          clearInterval(myAuthCheckInterval);
        }
      }
      // else {
      //   setSettings("Inactive");
      //   setAuth("Active");
      // }
    }, 5000);
    // unwanted commit
    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleAuthCheckZoho = async ({ headers, dataCenterUrl }) => {
    // for zoho auth verify
    const authCheck = await axios({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/verifyauth`,
      headers: headers,
    });

    console.log({ zohocheck: authCheck });

    if (authCheck?.data?.org?.length > 0) {
      /**
       * Create Default Widget
       */

      console.log({ dataCenterUrl });
      if (dataCenterUrl) {
        console.log({
          defaultFoldersCreatedUrl: `${process.env.REACT_APP_API_SERVER_URL}/googledrive/extfolders`,
        });
        const defaultFoldersCreated = await axios.request({
          url: `${process.env.REACT_APP_API_SERVER_URL}/googledrive/extfolders`,
          method: "POST",
          data: {},
          headers: {
            apikey: headers.apikey,
            connname: "easygoogledriveforcrm",
            orgid: headers.orgid,
            datacenterurl: dataCenterUrl,
          },
        });

        const createExtension = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
          method: "POST",
          data: {
            widgetName: "GoogleDrive by Easy Pluginz",
            widgetUrl:
              "https://widgets.v1.easygoogledriveforcrm__google.easy-pluginz.com/relatedlist",
            widgetDescription:
              "This widget you can utilizet in any Standard Module and Custom Module to visualize, crate, edit, delete folders and to crete shared links",
            widgetType: "RELATED_LIST",
            apiDomain: dataCenterUrl,
          },
          headers: {
            apikey: headers?.apikey,
            connname: "easygoogledriveforcrm__zoho",
            datacenterurl: dataCenterUrl,
            orgid: headers?.orgid,
          },
        });

        // Create Default Settings
        const createSettings = await axios.request({
          url: `${process.env.REACT_APP_API_SERVER_URL}/googledrive/extrelatedsettings`,
          method: "GET",
          headers: {
            apikey: headers?.apikey,
            datacenterurl: dataCenterUrl,
            connname: "easygoogledriveforcrm__google",
            orgid: headers?.orgid,
          },
        });
      }
      return true;
    } else {
      return false;
    }
  };

  const handleAuthCheckGoogle = async ({ headers }) => {
    const authCheck = await axios({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/google/verifyauth`,
      headers: headers,
    });
    console.log({ authCheck });
    if (authCheck?.data?.data) {
      /**
       * Create Default Widget
       */
      // if (dataCenterUrl) {
      //   const createExtension = await axios.request({
      //     url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
      //     method: "POST",
      //     data: {
      //       widgetName: "Workdrive by Easy Pluginz",
      //       widgetUrl:
      //         "https://widgets.v1.easygoogledriveforcrm__google.easy-pluginz.com/relatedlist",
      //       widgetDescription:
      //         "This widget you can utilizet in any Standard Module and Custom Module to visualize, crate, edit, delete folders and to crete shared links",
      //       widgetType: "RELATED_LIST",
      //       apiDomain: dataCenterUrl,
      //     },
      //     headers: {
      //       apikey: headers?.apikey,
      //       connname: "easygoogledriveforcrm__zoho",
      //       datacenterurl: dataCenterUrl,
      //       orgid: headers?.orgid,
      //     },
      //   });
      // }

      // Create Default Settings
      // const createSettings = await axios.request({
      //   url: `${process.env.REACT_APP_API_SERVER_URL}/zohoworkdrive/extrelatedsettings`,
      //   method: "GET",
      //   headers: {
      //     apikey: headers?.apikey,
      //     datacenterurl: dataCenterUrl,
      //     connname: "easygoogledriveforcrm__google",
      //     orgid: headers?.orgid,
      //   },
      // });
      return true;
    } else {
      return false;
    }
  };

  const handleRevokeGoogle = async () => {
    setRevokeLoadingGoogle(true);
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: apiKey,
        connname: "easygoogledriveforcrm__google",
      },
    });
    console.log(authUrlResp);

    if (authUrlResp?.data) {
      // console.log(authUrlResp?.data);
      setRevokeLoadingGoogle(false);
      setGoogleAuth(false);
    } else {
      setRevokeLoadingGoogle(false);
    }
  };

  const handleRevokeZoho = async () => {
    setRevokeLoadingZoho(true);
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: apiKey,
        connname: "easygoogledriveforcrm__zoho",
      },
    });
    console.log(authUrlResp);
    if (authUrlResp?.data) {
      setZohoAuth(false);
      setRevokeLoadingZoho(false);
    } else {
      setRevokeLoadingZoho(false);
    }
  };

  const fetchOrgVariablesData = async () => {
    /**
     * Get Organization Variables
     */
    const orgData = {
      apiKeys: [
        "easygoogledriveforcrm__API_KEY",
        "easygoogledriveforcrm__Organization_ID",
        "easygoogledriveforcrm__ZAPI_Key",
        "easygoogledriveforcrm__Data_Center_URL",
      ],
    };
    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
    console.log(
      "🚀 ~ file: Settings.js:306 ~ fetchOrgVariablesData ~ orgVariables:",
      orgVariables
    );

    setDataCenterUrl(
      orgVariables?.Success?.Content?.easygoogledriveforcrm__Data_Center_URL
        ?.value
        ? orgVariables?.Success?.Content?.easygoogledriveforcrm__Data_Center_URL
            ?.value != "null"
          ? orgVariables?.Success?.Content
              ?.easygoogledriveforcrm__Data_Center_URL?.value
          : dataCenterUrl
        : dataCenterUrl
    );
    widState.dataCenterUrl = orgVariables?.Success?.Content
      ?.easygoogledriveforcrm__Data_Center_URL?.value
      ? orgVariables?.Success?.Content?.easygoogledriveforcrm__Data_Center_URL
          ?.value != "null"
        ? orgVariables?.Success?.Content?.easygoogledriveforcrm__Data_Center_URL
            ?.value
        : dataCenterUrl
      : dataCenterUrl;
    setApiKey(
      orgVariables?.Success?.Content?.easygoogledriveforcrm__API_KEY?.value
    );
    widState.apiKey =
      orgVariables?.Success?.Content?.easygoogledriveforcrm__API_KEY?.value;
    setOrgId(
      orgVariables?.Success?.Content?.easygoogledriveforcrm__Organization_ID
        ?.value
    );
    widState.orgId =
      orgVariables?.Success?.Content?.easygoogledriveforcrm__Organization_ID?.value;
    setZapiKey(
      orgVariables?.Success?.Content?.easygoogledriveforcrm__ZAPI_Key?.value
    );

    const headersZoho = {
      apikey:
        orgVariables?.Success?.Content?.easygoogledriveforcrm__API_KEY?.value,
      connname: "easygoogledriveforcrm__zoho",
      orgid:
        orgVariables?.Success?.Content?.easygoogledriveforcrm__Organization_ID
          ?.value,
      accountsurl: orgVariables?.Success?.Content
        ?.easygoogledriveforcrm__Data_Center_URL?.value
        ? orgVariables?.Success?.Content?.easygoogledriveforcrm__Data_Center_URL
            ?.value != "null"
          ? orgVariables?.Success?.Content
              ?.easygoogledriveforcrm__Data_Center_URL?.value
          : dataCenterUrl
        : dataCenterUrl,
      dataCenterurlvariablename: "easygoogledriveforcrm__Data_Center_URL",
    };

    const headersGoogle = {
      apikey:
        orgVariables?.Success?.Content?.easygoogledriveforcrm__API_KEY?.value,
      connname: "easygoogledriveforcrm__google",
      orgid:
        orgVariables?.Success?.Content?.easygoogledriveforcrm__Organization_ID
          ?.value,
      services: "drive",
    };
    // ;
    /**
     * Fetch User Details
     */
    const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();
    // console.log(
    //   "🚀 ~ file: Settings.jsx:177 ~ fetchOrgVariablesData ~ orgResp",
    //   orgResp
    // );
    setZuid(orgResp?.org?.[0]?.primary_zuid);
    widState.zuid = orgResp?.org?.[0]?.primary_zuid;
    // console.log(
    //   "🚀 ~ file: Settings.js:999 ~ fetchOrgVariablesData ~ widState",
    //   JSON.stringify(widState)
    // );

    const authCheckStatusZoho = await handleAuthCheckZoho({
      headers: headersZoho,
      dataCenterUrl: orgVariables?.Success?.Content
        ?.easygoogledriveforcrm__Data_Center_URL?.value
        ? orgVariables?.Success?.Content?.easygoogledriveforcrm__Data_Center_URL
            ?.value != "null"
          ? orgVariables?.Success?.Content
              ?.easygoogledriveforcrm__Data_Center_URL?.value
          : dataCenterUrl
        : dataCenterUrl,
    });

    const authCheckStatusGoogle = await handleAuthCheckGoogle({
      headers: headersGoogle,
    });

    if (authCheckStatusZoho && authCheckStatusGoogle) {
      setSettings("Active");
      setAuth("Successful");
      setZohoAuth(true);
      setGoogleAuth(true);
    } else {
      authCheckStatusGoogle && setGoogleAuth(true);
      authCheckStatusZoho && setZohoAuth(true);
      // setSettings("Inactive");
      // setAuth("Active");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (zohoLoaded) {
      fetchOrgVariablesData();
    }
  }, [zohoLoaded]);

  if (!zohoLoaded) {
    return (
      <Box
        sx={{
          p: 2,
        }}
      >
        Data Not loaded yet.
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Please Wait While Fetching Data...{" "}
        </Typography>
        <CircularProgress sx={{ mt: 1 }} size={26} />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          bgcolor: "#F6F8FB",
          minHeight: "100vh",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            py: "12px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <h3 style={{marginLeft: "150px"}} ></h3> */}
          {/* <Typography
            sx={{
              mt: 1,
              mb: 1,
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "32px",
              fontWeight: 600,
              marginLeft: "160px"
            }}
          >
            WorkDrive to CRM
          </Typography> */}
          <Box sx={{ marginLeft: "160px" }}>
            <img
              src={workdrive_to_crm_logo}
              width={154}
              alt="workdrive to crm logo"
            />
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #1976D2",
              borderRadius: "5px",
              px: 2,
              my: 1,
              mr: 1,
            }}
          >
            <PlayCircleOutlineIcon sx={{ color: "#1976D2", mr: 1 }} />
            <a
              href="www.google.com"
              style={{
                textDecoration: "none",
                color: "#1976D2",
                fontWeight: "500",
              }}
            >
              {" "}
              View Tutorial
            </a>
          </Box> */}
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 4,
            mb: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {auth === "Active" ? (
            <Box
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {/* <Box
                sx={{
                  bgcolor: "#1F6DDE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 0.4,
                  borderRadius: "50%",
                  height: "35px",
                  width: "35px",
                  mr: 1,
                }}
              >
                <ViewModuleIcon sx={{ color: "white", fontSize: 19 }} />
              </Box>{" "} */}
              Authentication
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Inactive");
                setModuleRelatedSetting((prev) => "Inactive");
                setAuth((prev) => "Active");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
                width: 200,
              }}
            >
              Authenticated
            </Box>
          )}
          {settings === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              Module Settings
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setModuleRelatedSetting((prev) => "Inactive");
                setSettings((prev) => "Active");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Settings
            </Box>
          )}
          {moduleRelatedSetting === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              Related List Settings
            </Box>
          ) : (
            <Box
              onClick={() => {
                setModuleRelatedSetting((prev) => "Active");
                setFaq((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              Related List Settings
            </Box>
          )}
          {/* {faq === "Active" ? (
            <Box
              variant="outlined"
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                color: "#1976D2",
                width: 200,
                borderBottom: "3px solid #1976D2",
              }}
            >
              {" "}
              FAQ
            </Box>
          ) : (
            <Box
              onClick={() => {
                setFaq((prev) => "Active");
                setModuleRelatedSetting((prev) => "Inactive");
                setAuth((prev) => "Inactive");
                setSettings((prev) => "Inactive");
              }}
              sx={{
                textTransform: "none",
                border: "none",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
                width: 200,
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.38)",
              }}
            >
              FAQ
            </Box>
          )} */}
        </Box>

        {auth === "Active" && (
          <Overview
            handleRevokeGoogle={handleRevokeGoogle}
            handleRevokeZoho={handleRevokeZoho}
            authLoadingZoho={authLoadingZoho}
            authLoadingGoogle={authLoadingGoogle}
            revokeLoadingZoho={revokeLoadingZoho}
            revokeLoadingGoogle={revokeLoadingGoogle}
            zohoAuth={zohoAuth}
            googleAuth={googleAuth}
            handleAuthenticateZoho={handleAuthenticateZoho}
            handleAuthenticateGoogle={handleAuthenticateGoogle}
            dataCenterUrl={dataCenterUrl}
          />
        )}

        {settings === "Active" && <SettingsPage />}
        {moduleRelatedSetting === "Active" && <ModulesRelatedFieldSetting />}
        {faq === "Active" && <FAQ />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: 2,
            my: 2,
            // position: "fixed",
            // zIndex: -1,
            // right: 2,
            // bottom: 1,
          }}
        >
          <img
            src={logo}
            alt="Easy plugins logo"
            height={40}
            // style={{ marginLeft: "90px" }}
          />
        </Box>
      </Box>
    );
  }
}

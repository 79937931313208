import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function Overview({
  handleRevokeGoogle,
  handleRevokeZoho,
  revokeLoadingZoho,
  revokeLoadingGoogle,
  authLoadingZoho,
  authLoadingGoogle,
  zohoAuth,
  googleAuth,
  handleAuthenticateZoho,
  handleAuthenticateGoogle,
  // dataCenterUrl,
}) {
  // console.log({ dataCenterUrl });
  // const dataCenterUrlOptions = [
  //   "https://accounts.zoho.com",
  //   "https://accounts.zoho.eu",
  //   "https://accounts.zoho.com.au",
  //   "https://accounts.zoho.in",
  //   "https://accounts.zoho.com.cn",
  //   "https://accounts.zoho.jp",
  // ];
  // const [disable, setDisable] = useState(!dataCenterUrl);
  return (
    <Box
      sx={{
        py: 1,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mt: 2,
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          Authentication
        </Typography>
        <Divider />
        <br />
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Overview
        </Typography> */}
        <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",

            "li:not(:last-child)": {
              margin: "0 0 10px 0",
            },
          }}
        >
          Google Drive is a standalone app which needs to integrate with ZOHO
          using Rest API. To do Read/Create/Edit/Delete tasks in Google Drive
          you need to authenticate the Google Drive and ZOHO CRM to make
          Widgets/Workflow functional.
        </Box>
        {/* <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Select Data Center URL
        </Typography> */}
        {/* <Box
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            linetHeight: "24px",
          }}
        >
          Based on diffent ZOHO Data Center you need to select appropriate Data
          Center URL and Hit Authenticate and follow the steps to proceed.
        </Box> */}
      </Box>
      <Box sx={{ ml: 7, mt: 3 }}>
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataCenterUrlOptions}
          defaultValue={
            dataCenterUrl
              ? "https://accounts.zoho." +
                dataCenterUrl?.split("https://www.zohoapis.")?.[1]
              : ""
          }
          onChange={(e, v) => {
            if (v) {
              // setDataCenterUrl(v);
              setDisable(false);
            } else {
              // setDataCenterUrl();
              setDisable(true);
            }
          }}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Center Url"
              InputLabelProps={{ shrink: true }}
            />
          )}
        /> */}

        {googleAuth ? (
          <Button
            disabled={revokeLoadingGoogle}
            onClick={handleRevokeGoogle}
            sx={{
              mt: 2.5,
              mb: 2,
              mr: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {revokeLoadingGoogle ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                loading{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Rovoke Google Drive"
            )}
          </Button>
        ) : (
          <Button
            disabled={authLoadingGoogle}
            onClick={() => {
              handleAuthenticateGoogle();
              // setShow(true);
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              mr: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoadingGoogle ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Authenticate Google Drive"
            )}
          </Button>
        )}

        {zohoAuth ? (
          <Button
            disabled={revokeLoadingZoho}
            onClick={handleRevokeZoho}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {revokeLoadingZoho ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                loading
                <CircularProgress
                  sx={{ color: "white", ml: 1 }}
                  size={17}
                />{" "}
              </Box>
            ) : (
              "Revoke ZOHO CRM"
            )}
          </Button>
        ) : (
          <Button
            disabled={authLoadingZoho}
            onClick={() => {
              handleAuthenticateZoho();
              // setShow(true);
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "14rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1976D2",
            }}
            variant="contained"
          >
            {authLoadingZoho ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Verifying{" "}
                <CircularProgress sx={{ color: "white", ml: 1 }} size={17} />{" "}
              </Box>
            ) : (
              "Authenticate ZOHO CRM"
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
}

import React from "react";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";

import { Alert, AlertTitle, Grid } from "@mui/material";

import * as ApiCall from "../../../../../API/ApiCalling";
import Button from "@mui/material/Button";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxWidth: "1100px",
  width: "100%",
  height: "100vh",
  transform: "translate(-50%, -50%)",
  outline: "none",
  background: "#ffffff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
};

function ModalFile({
  open,
  setOpen,
  profileImage,
  file,
  settingId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
}) {
  const snap = useSnapshot(widState);

  const handleClose = () => {
    // // ;
    widState.setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Alert severity="success">
          <AlertTitle>
            <strong>Download File</strong>
          </AlertTitle>
          Please download the file
        </Alert>
        <Grid item>
          <Button
            size="small"
            sx={{ m: 2 }}
            onClick={async () => {
              widState.setLoading(false);
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 2, bgcolor: "#1976d2" }}
            onClick={async () => {
              widState.setLoading(true);
              setOpen(false);
              let response = await ApiCall.getImageResponse(
                file,
                connname,
                orgid,
                apikey,
                datacenterurl,
                settingUrl
              );
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", file?.name); //or any other extension
              document.body.appendChild(link);
              widState.setLoading(false);
              link.click();
            }}
          >
            Download
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ModalFile;

import React, { useEffect } from "react";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import PdfViewer from "../DisplaySection/PdfViewer";
import CloseIcon from "@mui/icons-material/Close";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import MyPdfViewer from "../DisplaySection/MyPdfViewer";

const imgSize = window.screen.width / 2.5;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // maxWidth: "1100px",
  width: `${imgSize}px`,
  height: "auto",
  transform: "translate(-50%, -50%)",
  outline: "none",
  background: "#ffffff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const buttonBoxStyle = {
  position: "absolute",
  top: "5%",
  left: "97%",
  transform: "translate(-50%, -50%)",
  msTransform: "translate(-50%, -50%)",
  background: "#ddd",
};

function ModalPdf({ open, setOpen, profileImage }) {
  const snap = useSnapshot(widState);

  const handleClose = () => {
    // // ;
    widState.setLoading(false);
    setOpen(false);
  };

  useEffect(() => {
    widState.setLoading(false);
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={buttonBoxStyle}>
          <CloseIcon
            fontSize="large"
            color="action"
            cursor="pointer"
            backgroundColor="red"
            onClick={handleClose}
          />
        </Box>
        {/* <MyPdfViewer
          width="100%"
          height="100%"
          alt=""
          sourceDocument={profileImage}
        /> */}
        <div className="control-section">
          <MyPdfViewer
            width="100%"
            height="100%"
            alt=""
            sourceDocument={profileImage}
          />
          {/* <PdfViewerComponent
            id="container"
            documentPath="../../../../../../../Discovery offer proposaal for Boosted CRM Test.pdf"
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "440px" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                FormFields,
                FormDesigner,
              ]}
            />
            
          </PdfViewerComponent> */}
        </div>
      </Box>
    </Modal>
  );
}

export default ModalPdf;
